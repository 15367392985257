import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authApi, setToken, restApi } from '../common/api';
import { me } from '../data/auth';
import { getSemesters } from '../data/common';
import { isMobile, localSetItem } from '../common/utils';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const SignIn = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    grant_type: 'password',
    username: window?.localStorage?.getItem('saveId') ?? '',
  });

  const [saveId, setSaveId] = useState(!!window?.localStorage?.getItem('saveId'));
  const [cookies, setCookie] = useCookies(['login_uuid']);


  const mobileFlag = isMobile();

  function handleSetCookies(data) {
    const cookieExpire = moment().add(data.uuidExpireIn, 'seconds').toDate();
    setCookie('login_uuid', data.uuid, { path: '/', expires: cookieExpire });
  }

  const handleSubmit = async () => {
    const args = {
      username: formData.username,
      password: formData.password,
      loginUuid: cookies.login_uuid,
    };

    try {

      console.log(args)
      localSetItem('saveId', saveId ? formData?.username : ''); 

      const { data } = await authApi.post(`/api/accounts/login`, args);
      setToken(data);
      handleSetCookies(data);

      const { payload } = await dispatch(me());
      if (['MANAGER', 'ADMIN', 'CP'].includes(payload?.principal?.type)) {
        dispatch(getSemesters());
      } else if (!['TEACHER', 'STAFF', 'MANAGER', 'ADMIN', 'CP','EXAMSTAFF'].includes(payload?.principal?.type)) {
        alert('권한이 없습니다.');
      }
      navigate('/');
    } catch (e) {
      const error = e.response;
      if (error && error.data) {
        const errMsg = error.data.message ?? error.data.error_description;
        if (errMsg === '로그인은 기기 당 한번만 가능합니다.') {
          if (
            window.confirm(
              '동일한 아이디로 중복로그인 되었습니다.\n기존에 로그인된 다른 기기는 자동으로 접속이 제한됩니다.\n로그인을 하시겠습니까?',
            )
          ) {
            const { data } = await restApi.post('/accounts/force-login', args);
            setToken(data);
            handleSetCookies(data);
            // setToken(null, true);
            // dispatch(clearPrincipal());
            const { payload } = await dispatch(me());
            console.log(payload)
            if (['MANAGER', 'ADMIN', 'CP'].includes(payload?.principal?.type)) {
              dispatch(getSemesters());
            } else if (!['TEACHER', 'STAFF', 'MANAGER', 'ADMIN', 'CP'].includes(payload?.principal?.type)) {
              alert('권한이 없습니다.');
            }
            navigate('/');
          } else {
            await dispatch(me());
            return;
          }
        } else if (errMsg === '') {
        alert('아이디 혹은 비밀번호가 올바르지 않습니다.');
      } else {
        alert(errMsg);
      }
    } else {
      alert(e.message);
    }
  }
};

  return (
    <div style={{ paddingTop: 100, paddingBottom: 100 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit().catch(console.warn);
        }}
      >
        <div className="lms-login-wrap">
          <section>
            <img
              className="cover-img"
              src="/images/lms/login_cover.png"
              alt="국내 최고의 브랜드 BMA. 성균대학교 경영 대학원 LMBA"
            />
          </section>
          <section className="login-page">
            <h3>로그인</h3>
            <p>관리자 입장을 위해 로그인이 필요합니다.</p>
            <label htmlFor="id" className="blind">
              아이디
            </label>
            <input
              id="id"
              type="text"
              placeholder="아이디를 입력해주세요."
              className="login-box"
              value={formData.username}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((x) => ({ ...x, username: value }));
              }}
            />
            <label htmlFor="pw" className="blind">
              비밀번호
            </label>
            <input
              id="pw"
              type="password"
              placeholder="비밀번호를 입력해주세요."
              className="login-box"
              onChange={(e) => {
                const value = e.target.value;
                setFormData((x) => ({ ...x, password: value }));
              }}
            />
            <div className="login-checkbox">
              <input type="checkbox" id="save-id" checked={saveId} onClick={() => setSaveId((x) => !x)} />
              <label htmlFor="save-id">아이디 저장</label>
            </div>
            <button type="submit" title="로그인">
              로그인
            </button>
            {mobileFlag && (
              <footer className="txt-c" style={{ marginTop: '0px', color: 'red', fontSize: '12px' }}>
                <p className="clearfix mb-0">
                  <span className="float-md-start d-block d-md-inline-block ">
                    IMBA 관리자 사이트는 노출되는 데이터가 많으므로 <br /> PC(태블릿)에서의 사용을 권장합니다
                  </span>
                </p>
              </footer>
            )}
            <footer className="txt-c" style={{ marginTop: '10px' }}>
              <p className="clearfix mb-0">
                <span className="float-md-start d-block d-md-inline-block mt-25 ">
                  성균관대 IMBA 교직원을 위한 시스템 입니다.
                  <br />​ 불법 사용시 법적 제제를 받을 수 있습니다.​ <br /> <hr /> &copy;
                  <a className="ms-25" href="https://www.skku.edu/skku/index.do" target="_blank" rel="noreferrer">
                    COPYRIGHT BY IMBA.
                  </a>
                  &nbsp; ALL RIGHTS RESERVED.​
                </span>
              </p>
            </footer>
          </section>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
